import React from "react";
import styled from "styled-components";

const Container = styled.div`
  padding: 1rem;
`;

const Title = styled.h3`
  color: #fff;
  font-size: 1.6rem;
`;

const ListItem = styled.li`
  color: #fff;
`;

const Link = styled.a`
  text-decoration: underline;
  color: inherit;
`;

const ContactInfoList = props => {
  const { name, title, phonenumber, phonenumberLink, email, emailLink } = props;

  return (
    <Container>
      <Title>{name}</Title>
      <ul>
        <ListItem>{title}</ListItem>
        <ListItem>
          {"Puh. "}
          <Link href={`tel:${phonenumberLink}`}>{phonenumber}</Link>
        </ListItem>
        <ListItem>
          <Link href={`mailto:${emailLink}`}>{email}</Link>
        </ListItem>
      </ul>
    </Container>
  );
};

export default ContactInfoList;
