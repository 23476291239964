import React from "react";
import styled from "styled-components";

import ContactInfoBox from "../components/contactInfoBox";
import ContentContainer from "../components/contentContainer";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    margin-bottom: 1rem;
  }
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 2rem;

  > * :not(:last-child) :not(:first-child) {
    margin-bottom: 1rem;
  }

  @media only screen and (max-width: 800px) {
    max-width: 90%;
    justify-content: center;
    margin-right: 0;
    margin-bottom: 2rem;
  }
`;

const InfoText = styled.p`
  display: flex;
  flex-direction: column;
  > * :not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const CompanyTitle = styled.h3`
  color: #0d2b4c;
  @media only screen and (max-width: 1000px) {
    font-size: 1.6rem;
  }
`;

const CompanyInfo = styled.h2`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  @media only screen and (max-width: 1000px) {
    font-size: 2rem;
  }
`;

const AboutSection = () => {
  return (
    <ContentContainer>
      <Container>
        <InfoBox>
          <CompanyTitle>INSINÖÖRITOIMISTO KANTELINEN</CompanyTitle>
          <CompanyInfo>
            <span>Rakenne- ja elementti-</span>
            <span>suunnittelua Joensuussa</span>
          </CompanyInfo>
          <InfoText>
            <span>
              Tarjoamme asiakkaillemme ammattitaitoista rakenne- ja
              elementtisuunnittelua Itä-Suomen alueella. Toimintamme perustuu
              vuosikymmenien kokemukseen, rohkeaan uuden tiedon omaksumiseen
              sekä tinkimättömään asenteeseen palvella jokaista asiakastamme
              rehellisesti parhaan kykymme mukaan.
            </span>
            <span>
              Yhdessä toimien kartoitamme jokaisen projektin ja asiakkaan
              yksilölliset vaatimukset ja löydämme juuri heidän tarpeisiinsa
              parhaiten soveltuvat kustannustehokkaat, käytännölliset ja
              kestävät ratkaisut. Kannamme vastuun suunnitelmistamme ja ylpeinä
              pidämme kiinni maineestamme luotettavana ja toimitusvarmana
              yhteistyökumppanina.
            </span>
          </InfoText>
        </InfoBox>

        <ContactInfoBox />
      </Container>
    </ContentContainer>
  );
};

export default AboutSection;
