import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const Head = props => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const { title = "" } = props;

  return (
    <Helmet title={`${title}${data.site.siteMetadata.title}`}>
      <html lang="fi" />
      <meta
        name="Description"
        content="Tarjoamme asiakkaillemme ammattitaitoista rakenne- ja elementtisuunnittelua. Toimintamme perustuu vuosikymmenien kokemukseen, rohkeaan uuden tiedon omaksumiseen sekä tinkimättömään asenteeseen palvella jokaista asiakastamme rehellisesti parhaan kykymme mukaan."
      ></meta>
    </Helmet>
  );
};

export default Head;
