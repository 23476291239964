import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";

import WhiteLogo from "../assets/svg/kantelinen-logo-K-white.svg";

const HeroImage = styled(Img)`
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
  filter: blur(8px);
  height: 100%;
  width: 100%;
`;

const Overlay = styled.div`
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
  position: absolute;
  background: #0d2b4c;
  opacity: 0.8;
  z-index: 2;
  height: 100%;
  width: 100%;
`;

const Container = styled.div`
  position: relative;
  height: 90vh;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
`;

const Title = styled.h1`
  display: flex;
  flex-direction: column;
  font-weight: 700;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  color: white;
  white-space: nowrap;
`;

const Logo = styled.img`
  max-width: 10rem;
`;

const Hero = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      file(relativePath: { eq: "images/hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, maxHeight: 1080) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <Container>
      <TitleContainer>
        <Logo src={WhiteLogo} alt="Company Logo" />
        <Title>
          <span>Ammattitaitoista rakenne- ja</span>
          <span>elementtisuunnittelua</span>
        </Title>
      </TitleContainer>
      <Overlay />
      <HeroImage fluid={data.file.childImageSharp.fluid}></HeroImage>
    </Container>
  );
};

export default Hero;
