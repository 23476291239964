import React from "react";
import styled from "styled-components";

import ContactInfoList from "./contactInfoList";
import List from "./list";
import Logo from "./logo";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #0d2b4c;
  align-items: flex-start;
`;

const ContentContainer = styled.div`
  padding: 2rem;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  > * {
    width: 50%;
  }

  @media only screen and (max-width: 500px) {
    flex-direction: column;
    > * {
      width: 100%;
    }
  }
`;

const LocationLinkContainer = styled.div`
  margin-top: 1rem;
`;

const LocationLink = styled.a`
  font-size: 1.4rem;
  color: #fff;
  text-decoration: underline;
  font-family: inherit;
  font-weight: 700;
`;

const ContactInfoContainer = styled.div`
  margin: 1rem;
`;

const general = ["Jokikatu 7 A 39", "80220, Joensuu"];

const people = [
  {
    name: "Ville Kantelinen",
    title: "Toimitusjohtaja",
    phonenumber: "050 464 4760",
    phonenumberLink: "+358-50-464-4760",
    email: "ville.kantelinen(at)kantelinen.fi",
    emailLink: "ville.kantelinen@kantelinen.fi",
  },
  {
    name: "Markku Kantelinen",
    title: "Hallituksen puheenjohtaja",
    phonenumber: "050 400 5895",
    phonenumberLink: "+358-50-400-5895",
    email: "markku.kantelinen(at)kantelinen.fi",
    emailLink: "markku.kantelinen@kantelinen.fi",
  },
  {
    name: "Teela Kortelainen",
    title: "Suunnittelija",
    phonenumber: "050 475 0565",
    phonenumberLink: "+358-50-475-0565",
    email: "teela.kortelainen(at)kantelinen.fi",
    emailLink: "teela.kortelainen@kantelinen.fi",
  },
  {
    name: "Heikki Puustinen",
    title: "Suunnittelija",
    phonenumber: "050 305 6707",
    phonenumberLink: "+358-50-305-6707",
    email: "heikki.puustinen(at)kantelinen.fi",
    emailLink: "heikki.puustinen@kantelinen.fi",
  },
  {
    name: "Elli Pietilä",
    title: "Suunnittelija",
    phonenumber: "050 464 4405",
    phonenumberLink: "+358-50-464-4405",
    email: "elli.pietila(at)kantelinen.fi",
    emailLink: "elli.pietila@kantelinen.fi",
  },
  {
    name: "Esa Koskinen",
    title: "Suunnittelija",
    phonenumber: "050 516 5411",
    phonenumberLink: "+358-50-516-5411",
    email: "esa.koskinen(at)kantelinen.fi",
    emailLink: "esa.koskinen@kantelinen.fi",
  },
  {
    name: "Marjo Leppänen",
    title: "Suunnittelija",
    phonenumber: "050 516 5417",
    phonenumberLink: "+358-50-516-5417",
    email: "marjo.leppanen(at)kantelinen.fi",
    emailLink: "marjo.leppanen@kantelinen.fi",
  },
];

const ContactInfoBox = () => {
  return (
    <Container>
      <ContentContainer>
        <ContactInfoContainer>
          <Logo type="white" />
          <List title="Yhteystiedot" items={general} color="#fff" />
          <LocationLinkContainer>
            <LocationLink
              href="https://goo.gl/maps/qLsfzFvk5wARSGa3A"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sijainti kartalla
            </LocationLink>
          </LocationLinkContainer>
        </ContactInfoContainer>
        <ItemContainer>
          <ContactInfoList {...people[0]} />
          <ContactInfoList {...people[1]} />
        </ItemContainer>
        <ItemContainer>
          <ContactInfoList {...people[2]} />
          <ContactInfoList {...people[3]} />
        </ItemContainer>
        <ItemContainer>
          <ContactInfoList {...people[4]} />
          <ContactInfoList {...people[5]} />
        </ItemContainer>
        <ItemContainer>
          <ContactInfoList {...people[6]} />
        </ItemContainer>
      </ContentContainer>
    </Container>
  );
};

export default ContactInfoBox;
