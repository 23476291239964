import React from "react";

import Head from "../components/head";
import Hero from "../components/hero";
import Layout from "../components/layout";
import AboutSection from "../sections/about";

import "@fontsource/open-sans/300.css"
import "@fontsource/open-sans/400.css"
import "@fontsource/montserrat/400.css"
import "@fontsource/montserrat/700.css"

const IndexPage = () => {
  return (
    <Layout>
      <Head />
      <Hero />
      <AboutSection />
    </Layout>
  );
};

export default IndexPage;
